import React from 'react'
import AstroVid1 from '../assets/vids/astro-vid1.mp4'
import AstroVid2 from '../assets/vids/astro-vid2.mp4'
import AstroVid3 from '../assets/vids/astro-vid3.mp4'
import AstroDetail1 from '../assets/imgs/astro-details1.png'
import AStroDetail2 from '../assets/imgs/astro-details2.png'

function Featured() {
  return (
    <div className='max-w-[1240px] mx-auto mb-4 px-4'>
        <h1 className='text-center py-4 w-full'>Trendylis Astronoaut Nebula Projector</h1>
        <div className='sm:grid sm:grid-rows-none md:grid-cols-5 py-4 gap-2 md:gap-4 w-full'>
            <video 
                className='w-full h-full object-cover col-span-2 md:col-span-3 row-span-2'
                src={AstroVid1}
                autoPlay
                loop
                muted
                alt="Trendylis Astronaut Video 1"
            />
            <video 
                className='w-full h-full object-cover'
                src={AstroVid2}
                autoPlay
                loop
                muted
                alt="Trendylis Astronaut Video 2"
            />
            <video 
                className='w-full h-full object-cover'
                src={AstroVid3}
                autoPlay
                loop
                muted
                alt="Trendylis Astronaut Video 3"
            />
            <img className='w-full h-full object-cover' src={AstroDetail1} alt="/" />
            <img className='w-full h-full object-cover' src={AStroDetail2} alt="/" />            
        </div>
        <div className='sm:py-4 pb-12 sm:pb-4'>
            <p>
            Introducing the ultimate astronaut toy that will take your child's imagination to the stars! 
            The Trendylis Astronaut Nebula Projector is designed with intricate details that will make your child feel like they're blasting off into space.             
            </p>
            <p className='py-2'>
            This toy comes equipped with a built-in projector that creates a stunning display of stars and colorful nebulas on the ceiling. 
            With just a press of a button, your child can transform their bedroom into a galaxy of stars, constellations, and planets.            
            </p>
            <p>
            The Trendylis Astronaut Nebula Projector is easy to use and comes with a variety of starry colors to choose from, allowing your child to customize their very own celestial sky. 
            The Trendylis Astronaut Nebula Projector is made from high-quality, durable materials and is built to withstand the wear and tear of everyday play. 
            </p>
            <p className='py-2'>
            The Trendylis Astronaut Nebula Projector is also easy to clean, ensuring that it remains in pristine condition for years to come. The Trendylis Astronaut Nebula Projector is not only fun and entertaining, but it also encourages creativity, imagination, and a love for science and space exploration. It's the perfect gift for any child who dreams of one day becoming an astronaut and exploring the vast expanse of space. So, let your child's imagination soar with The trendylis Astronaut Nebula Projector that projects stars and nebulas on the ceiling. 
            It's an out-of-this-world experience that your child will cherish for years to come!
            </p>
        </div>
    </div>
  )
}

export default Featured