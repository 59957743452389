import React from 'react'
import BackgroundSlider from '../BackgroundSlider'

const Hero = () => {

  return (
    <div className='w-full h-screen relative'>
        <BackgroundSlider />
    </div>
  )
}

export default Hero