import React from 'react'

// import shopData from '../shopData.js'

function ShopList() {
  return (
    <div className='w-full md:h-[80vh] xxxsm:h-[250vh] xxsm:h-[200vh] xsm:h-[200vh] sm:h-[200vh] flex justify-center'>
      <div className='absolute top-24 lg:w-[1240px] p-2'>
          <div className='p-2 sm:grid sm:grid-rows-none md:grid-cols-3 py-4 gap-2 md:gap-4 w-full'>

            {/* Product 1  */}
            <div className='sm:h-[500px] border-solid border-2 border-gray-200 hover:scale-105'>
                <h3 className='p-4 text-center'>Trendylis Astronaut Projector</h3>
                <p className='text-center'>Gift for everyone</p>
                <div className='h-[350px] p-4'>
                    <img className='h-full object-cover' src='./imgs/products/astronaut-1.png' alt="/" />
                </div>
                <div className='bg-gray-500 text-white p-4'>
                    <h3>$79.99</h3>
                </div>
            </div>

            {/* Product 2  */}
            <div className='sm:h-[500px] border-solid border-2 border-gray-200 mt-4 sm:mt-0 hover:scale-105'>
                <h3 className='p-4 text-center'>Magnetic Car Charger</h3>
                <p className='text-center'>iPhone 12/13/14</p>
                <div className='h-[350px] p-4'>
                    <img className='h-full object-cover' src='./imgs/products/carcharger-1.png' alt="/" />
                </div>
                <div className='bg-gray-500 text-white p-4 flex justify-between'>
                    <h3>$39.99</h3>
                    <h3 className='text-red-300'>sold out</h3>
                </div>
            </div>

            {/* Product 3  */}
            <div className='sm:h-[500px] border-solid border-2 border-gray-200 mt-4 sm:mt-0 hover:scale-105'>
                <h3 className='p-4 text-center'>RC Flying Shark Balloon</h3>
                <p className='text-center'>Gift for everyone</p>
                <div className='h-[350px] p-4'>
                    <img className='h-full object-cover' src='./imgs/products/flyingshark-1.png' alt="/" />
                </div>
                <div className='bg-gray-500 text-white p-4'>
                    <h3>$49.99</h3>
                </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default ShopList