import React from 'react'

const WarrantyPolicy = () => {
  return (
    <div className='w-full md:h-[120vh] xxxsm:h-[340vh] xxsm:h-[250vh] xsm:h-[220vh] sm:h-[180vh] flex justify-center'>
      <div className='absolute top-24 lg:w-[1240px] p-8'>
            <div>
                <h1>90 Days Warranty Policy</h1>
                <p className='mt-2'>
                    Trendylis offers a 90-day warranty policy. We guarantee our products and or services to work as intended for a period of 90 days, after the purchase or delivery date. 
                    During this period, the company will offer free repairs, replacements, or refunds (if unrepairable) for any defects or malfunctions that occur due to normal use.
                </p>
                <p className='mt-2'>
                    A 90-day warranty is a common policy in many industries, such as electronics, appliances, and automotive parts. 
                    With this type of policy, we provide our customers with a certain level of protection and peace of mind, knowing that they have a limited time to test the product and make sure it meets their expectations.
                </p>
                <p className='mt-2'>
                It is important to note that our 90-day warranty does not cover damages such as, physical, water/liquid, any malfunctions caused by misuse, neglect, or accidental damage and shipping fees. 
                If the product is damaged due to user error or external factors, the warranty will not apply.
                </p>
                <p className='mt-2'>
                Overall, our 90-day warranty policy can benefit all our customers ensureing that our customers has a certain level of protection and 
                satisfaction with their purchase.
                </p>
            </div>
            <div className='md:mt-16 mt-10'>
                <h1> Returns and Refunds </h1>
                <p className='mt-2'>
                    At Trendylis, we believe in providing our customers with the highest quality products and services. 
                    We understand that sometimes a product may not meet your expectations or requirements. 
                    To ensure customer satisfaction, we offer 30-days for returns and refunds.
                </p>
                <p className='mt-2'>
                    If you are not completely satisfied with your purchase, you may return the item within 30 days from the date of purchase or delivery (which ever is later) for a full refund. 
                    The item must be in its original condition, with all tags and packaging intact, and must not have been used or worn.   
                </p>
                <p className='mt-2'>
                    To initiate a return, please contact our customer service team at 905-867-4050, and provide your order number and reason for the return. 
                    Our team will guide you through the return process and provide you with a reference number.
                </p>
                <p className='mt-2'>
                    Once we receive the returned item, we will inspect it and issue a refund to 
                    the original payment method within 5-7 business days.
                </p>
                <p className='mt-2'>
                    Please note that certain items may be subject to specific return policies, and 
                    some items may not be eligible for returns. These items will be clearly marked on our website.                    
                </p>
                <p className='mt-2'>
                    At Trendylis, we strive to provide our customers with the best shopping experience possible. 
                    If you have any questions or concerns regarding our returns and refunds policy, 
                    please do not hesitate to contact our customer service team.
                </p>
            </div>
      </div>
    </div>
  )
}

export default WarrantyPolicy