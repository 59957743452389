import React from 'react'
import {FaFacebook, FaInstagram, FaTwitter, FaYoutube} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='w-full bg-gray-500 py-16'>
        <div className='max-w-[1240px] mx-auto flex flex-col px-4'>
            <div className='text-center justify-between items-center'>
                <h1>TRENDYLIS</h1>
                <div className='flex justify-between items-center'>
                    <div>
                        <ul className='text-left'>
                            <Link to="/about"><li>About</li></Link>
                            <Link to="/warranty-policy"><li>Returns & Warranty</li></Link>
                            <Link to="/shop"><li>Shop</li></Link>
                            <li>Contact</li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/people/Trendylis/100090779723695/"><li><FaFacebook className='icon' /></li></a>
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/trendylis23/"><li><FaInstagram className='icon' /></li></a>
                            <li><FaTwitter className='icon' /></li>
                            <li><FaYoutube className='icon' /></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer