import React from 'react'
import tlLogo from '../assets/imgs/logo-tl.png'

const About = () => {

  return (
    <div className='w-full md:h-[80vh] xxxsm:h-[170vh] xxsm:h-[130vh] xsm:h-[110vh] sm:h-[120vh] flex justify-center'>
      <div className='absolute top-24 lg:w-[1240px] p-2'>
          <div className='p-2'>
            <h1> About </h1>
            <p className='mt-2 md:mt-4'>  
              Trendylis is a brand that was founded on February 12, 2019. 
              The brand is known for its high-quality, innovative products and its commitment to inclusivity and diversity.
            </p>
            <p className='mt-2 md:mt-4'>
              Tendylis uses eco friendly and recycled materials in its products to help reduce carbon emissions. 
              Trendylis is also known for its cutting-edge designs, such as the Astronaut Nebula Projector that assist children with their imagination before going falling asleep.
            </p>
            <p className='mt-2 md:mt-4'>
              In addition to its commitment to quality, Trendylis is also known for its inclusivity and diversity. 
              The brand offers a wide range of toys, electronics, and apparels for everyone. 
              It has been praised for its efforts to feature models of all ages, genders, and ethnicities in its advertising campaigns.
            </p>
            <p className='mt-2 md:mt-4'>
              Trendylis also has a strong social media presence and has garnered a cult following trendy enthusiasts. 
              The brand's products often sell out quickly, and its limited-edition releases are highly sought after by collectors and fans.
            </p>
            <p className='mt-2 md:mt-4'>
              Overall, Trendylis is a brand that stands out for its commitment to quality, innovation, design, and inclusivity. 
              Its products are designed to empower and inspire, and its dedication to diversity has made it a beloved brand among enthusiasts of all backgrounds.
            </p>
          </div>
          <div className='flex justify-center'>
            <img className='md:w-[150px] w-[100px] h-full object-cover md:mt-16' src={tlLogo} alt="/" />
          </div>
      </div>
    </div>
  )
}

export default About