import React, {useState} from 'react';
import {CiMenuBurger} from 'react-icons/ci';
import {AiOutlineClose} from 'react-icons/ai';
// import {HiOutlineMenuAlt4} from 'react-icons/hi';
import {FaFacebook, FaInstagram, FaTwitter, FaYoutube} from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Navbar  =() => {
    const [nav, setNav] = useState(false);
    const [logo, setLogo] = useState(false);

    const handleNav = () => {
        setNav(!nav);
        setLogo(!logo);
    }


  return (
    <div className='flex w-full justify-between items-center h-20 px-4 fixed z-10 bg-slate-600/60'>
        <div>
            <h1 onClick={handleNav} className={logo ? 'z-10 hidden md:block cursor-pointer' : 'z-10 block text-white cursor-pointer'}>TRENDYLIS</h1>
        </div>
        <ul className='hidden md:flex cursor-pointer text-white'>
            <Link to="/"><li>Home</li></Link>
            <Link to="/about"><li>About</li></Link>
            <Link to="/shop"><li >Shop</li></Link>
            <li>Contact</li>
        </ul>
{/* Hamburger  */}
        <div onClick={handleNav} className='md:hidden z-10'>
            {nav ? <AiOutlineClose className='text-black z-10' size={30}/> : <CiMenuBurger className='text-white' size={30}/>}
        </div>

{/* Mobile Menu Drop Down  */}
        <div onClick={handleNav} className={nav ? 'absolute left-[0] top-0 w-full bg-gray-300/90 px-4 py-7 flex flex-col md:hidden' : 'hidden'}>
            <ul>
                <h1>TRENDYLIS</h1>
                <Link to="/"><li className='border-b'>Home</li></Link>
                <Link to="/about"><li className='border-b'>About</li></Link>
                <Link to="/shop"><li className='border-b'>Shop</li></Link>
                <li className='border-b'>Contact</li>
                <div className='flex justify-between my-7'>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/people/Trendylis/100090779723695/"><FaFacebook className='icon' /></a>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/trendylis23/"><FaInstagram className='icon' /></a>
                    <FaYoutube className='icon' />
                    <FaTwitter className='icon' /> 
                </div>
            </ul>
        </div>
    </div>
  );
}

export default Navbar;