import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './components/About';
import Home from './components/Home';
import WarrantyPolicy from './components/WarrantyPolicy';
import ShopList from './components/ShopList';

function App() {
  return (
    <div className="App">
    <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='shop' element={<ShopList />} />
        <Route path='warranty-policy' element={<WarrantyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
