import React from 'react'
import Hero from '../components/Hero.jsx'
import Featured from '../components/Featured.jsx'

function Home() {
  return (
    <div>
        <Hero />
        <Featured />
    </div>
  )
}

export default Home