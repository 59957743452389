import React, { useState, useEffect } from 'react'
import '../src/BackgroundSlider.css'
import imageSlide from './data.js'

const BackgroundSlider = () => {
  const[currentState, setCurrentState] = useState(0)
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if(currentState === 2){
        setCurrentState(0)
      } 
      else {
        setCurrentState(currentState + 1)
      }
    }, 5000)

    return () => clearTimeout(timer)

  },[currentState])
  
  const bgImageStyle = {
    backgroundImage: `url(${imageSlide[currentState].url})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '95%',
    width: '100%',
  }

  const goToNext = (currentState) => {
    setCurrentState(currentState)
  }

  return (
    <div className='w-full h-full z-[-1]'>
      <div style={bgImageStyle}></div>
      <div className='transparent-bg'></div>
      <div className='description md:w-26 top-[70%] left-[5%]'>
        <div>
          <h1 className='text-white'>{imageSlide[currentState].title}</h1>
          <p>{imageSlide[currentState].body}</p>
        </div>
        <div className='carousel-bullet'>
          {
            imageSlide.map((imageSlide,currentState) => (
              <span key={currentState} onClick={ () => goToNext(currentState)} className='my-8 m-2'></span>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default BackgroundSlider