const imageSlide = [
    {
        url: './imgs/astro-1v3.png',
        title: 'The imagination starts from within',
        body: 'Trendylis Astronaut Nebula Projector',
    },
    {
        url: './imgs/astro-2v1.png',
        title: 'Creativity brings the light in you',
        body: 'Trendylis Astronaut Nebula Projector',
    },
    {
        url: './imgs/hero-2.png',
        title: 'We make trends happen',
        body: 'Trendylis',
    },
]

export default imageSlide